/* --- general --- */


.clearfix:after
{
    font-size: 0px;
    content: ".";
    display: block;
    height: 0px;
    visibility: hidden;
    clear: both;
}
body
{
	background: #F0F0F0;
	padding: 0px;
	margin: 0px;
	font-family: 'Roboto';
	font-size: 14px;
	color: #FFF;
}
a
{
	text-decoration: none;
	outline: none;
}
a:hover
{
	text-decoration: underline;
}
p
{
	padding: 1em 0;
	color: #3E3E3E;
	line-height: 150%;
}
p.text
{
	font-size: 16px;
}
p a
{
	color: #ED1C24;
}
strong
{
	color: #000;
}
blockquote
{
	padding-left: 25px;
	color: #25282A;
	font-size: 27px;
	font-family: 'Roboto';
	font-weight: bold;
	line-height: 120%;
	background: #F0F0F0 url("../images/icons/other/quote_content.png") no-repeat 20px 20px;
	padding: 50px 20px 20px;
}
blockquote.simple
{
	background-color: transparent;
	background-position: 0 20px;
	padding-left: 0;
	padding-right: 0;
	padding-bottom: 0;
	font-size: 18px;
}
pre
{
	font-family: 'Roboto';
    font-size: 14px;
    line-height: 170%;
	background: url('../images/backgrounds/images/overlay.png') repeat;
	text-shadow: 0 1px 0 #FFF;
	zoom:1;
	filter: dropshadow(color=#ffffff, offx=0, offy=1); 
	padding: 0 5px;
	margin: 0;
	overflow: auto;
}
label
{
	color: #25282A;
	font-family: 'Roboto';
}
.relative
{
	position: relative;
}
.responsive
{
	display: block;
	width: 100%;
	height: auto;
}
input, textarea
{
	font-family: arial;
	background: #F0F0F0;
	border: none;
	font-size: 14px;
	padding: 15px 20px;
	color: #3E3E3E;
}
.center_align 
{
	text-align: center;
}
.margin_top_0
{
	margin-top: 0 !important;
}
.margin_top_10
{
	margin-top: 10px !important;
}
.margin_top_15
{
	margin-top: 15px;
}
.margin_top_20
{
	margin-top: 20px !important;
}
.padding_top_0
{
	padding-top: 0;
}
.padding_top_30
{
	padding-top: 30px;
}
.padding_top_bottom_25
{
	padding: 25px 0;
}
.padding_top_bottom_15
{
	padding: 15px 0 !important;
}
.nounderline,
.nounderline:hover
{
	text-decoration: none;
}
/* --- body styles --- */
body.image_1
{
	background: url("../images/backgrounds/images/image_1.jpg") fixed no-repeat;
}
body.image_2
{
	background: url("../images/backgrounds/images/image_2.jpg") fixed no-repeat;
}
body.image_3
{
	background: url("../images/backgrounds/images/image_3.jpg") fixed no-repeat;
}
body.image_4
{
	background: url("../images/backgrounds/images/image_4.jpg") fixed no-repeat;
}
body.image_5
{
	background: url("../images/backgrounds/images/image_5.jpg") fixed no-repeat;
}
body.pattern_1
{
	background: url("../images/backgrounds/patterns/pattern_1.png") fixed;
}
body.pattern_2
{
	background: url("../images/backgrounds/patterns/pattern_2.png") fixed;
}
body.pattern_3
{
	background: url("../images/backgrounds/patterns/pattern_3.png") fixed;
}
body.pattern_4
{
	background: url("../images/backgrounds/patterns/pattern_4.png") fixed;
}
body.pattern_5
{
	background: url("../images/backgrounds/patterns/pattern_5.png") fixed;
}
body.pattern_6
{
	background: url("../images/backgrounds/patterns/pattern_6.png") fixed;
}
body.pattern_7
{
	background: url("../images/backgrounds/patterns/pattern_7.png") fixed;
}
body.pattern_8
{
	background: url("../images/backgrounds/patterns/pattern_8.png") fixed;
}
body.pattern_9
{
	background: url("../images/backgrounds/patterns/pattern_9.png") fixed;
}
body.pattern_10
{
	background: url("../images/backgrounds/patterns/pattern_10.png") fixed;
}
body.overlay .background_overlay
{
	display: block;
}
.background_overlay
{
	position: fixed;
	display: none;
	width: 100%;
	height: 100%;
	top: 0;
	background: url("../images/backgrounds/images/overlay.png");
	z-index: 1;
}
/* --- site container --- */
.site_container
{
	background: #FFF;
}
.site_container.boxed
{
	max-width: 1150px;
	margin-left: auto;
	margin-right: auto;
	box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
	-moz-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
	-webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
}
body.overlay .site_container
{
	position: relative;
	z-index: 2;
}
.site_container.boxed .header_top_bar
{
	width: auto;
}
.site_container.boxed .header_top_bar .latest_news_scrolling_list_container .category
{
	border-left: none;
}
.site_container.boxed .header_top_bar .search
{
	border-right: none;
}
.site_container.boxed .slider,
.site_container.boxed .slider li.slide
{
	width: 1150px;
	height: 508px;
}
.site_container.boxed .slider li.slide img
{
	width: 1155px;
}
.site_container.boxed .slider_content_box
{
	left: 55px;
	top: 231px;
}
.site_container.boxed .small_slider .slider_content_box
{
	left: 0;
	top: auto;
}
/* --- headers --- */
h1, h2, h3, h4, h5, h6
{
	font-family: 'Roboto';
	margin: 0;
	padding: 0;
	font-weight: normal;
	color: #25282A;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a
{
	color: #25282A;
}
.footer h1, .footer h2, .footer h3, .footer h4, .footer h5, .footer h6,
.footer h1 a, .footer h2 a, .footer h3 a, .footer h4 a, .footer h5 a, .footer h6 a
{
	color: #FFF;
}
h1
{
	font-size: 42px;
	line-height: 110%;
}
h2
{
	font-size: 27px;
	line-height: 120%;
}
h3
{
	font-size: 16px;
	line-height: 140%;
}
h4
{
	font-size: 18px;
	line-height: 140%;
}
h5
{
	font-size: 16px;
	line-height: 140%;
}
h6
{
	font-size: 13px;
	line-height: 150%;
}
.box_header
{
	background: #F0F0F0;
	border-left: 3px solid #ED1C24;
	padding: 8px 15px 11px;
}
.footer .box_header
{
	background: #42494F;
	border-color: #858D94;
}
.about_title,
.about_subtitle
{
	font-family: 'Roboto';
	font-weight: 300;
	font-size: 55px;
}
.about_subtitle
{
	font-weight: 700;
	color: #ED1C24;
}
/* --- header --- */
.header_container
{
	background-color: #fff;
	padding-bottom: 26px;
}
.header_top_bar_container
{
	background-color: #363B40;
}
.header_top_bar_container.border
{
	border-bottom: 1px solid #464D53;
}
.header_top_bar,
.header,
.menu_container .sf-menu,
.footer
{
	width: 1050px;
	margin-left: auto;
	margin-right: auto;
}
.header
{
	text-align: center;
	padding-top: 7px;
}
.header h1
{
	font-size: 96px;
	font-weight: 700;
	font-family: 'Roboto Condensed';
	color: #ED1C24;
	letter-spacing: -0.01em;
	line-height: 1;
}
.header h1 a
{	
	color: #ED1C24;
}
.header h4
{
	color: #7C7C7C;
	line-height: 1;
	margin-top: 3px;
}
.header .placeholder
{
	display: none;
	float: right;
	font-size: 30px;
	font-family: 'Roboto Condensed';
	font-weight: 300;
	background: #F0F0F0;
	width: 728px;
	height: 110px;
	padding-top: 29px;
	margin-top: 7px;
	color: #ffffff;
	text-align: center;
}
.header_top_bar
{	
	overflow: hidden;
	height: 46px;
}
.header_top_bar .caroufredsel_wrapper_vertical_carousel
{
	height: 46px !important;
}
/* --- header top bar styles --- */
/* - style 2 & 3 & 4 - */
.style_2.header_top_bar_container
{
	background: #FFF;
}
.style_2.border.header_top_bar_container
{
	border-bottom: 1px solid #E9E9E9;
}
.style_2 .latest_news_scrolling_list_container .category,
.style_2 .latest_news_scrolling_list_container li.left, 
.style_2 .latest_news_scrolling_list_container li.right,
.style_2 .search,
.style_2 .search .search_input
{
	border-color: #E9E9E9;
}
.style_2 .search .search_input,
.style_3 .search .search_input,
.style_4 .search .search_input
{
	background: transparent;
}
.style_2 .latest_news_scrolling_list_container .category,
.style_2 .latest_news_scrolling_list_container .category a,
.style_2 .latest_news_scrolling_list_container li .latest_news_scrolling_list a,
.style_2 .search .search_input,
.style_2 .search .search_input.hint,
.style_3 .latest_news_scrolling_list_container .category,
.style_3 .latest_news_scrolling_list_container .category a,
.style_3 .latest_news_scrolling_list_container li .latest_news_scrolling_list a,
.style_3 .search .search_input,
.style_3 .search .search_input.hint
{
	color: #25282A;
}
.style_2 .latest_news_scrolling_list_container li .timeago,
.style_3 .latest_news_scrolling_list_container li .timeago
{
	color: #ABABAB;
}
.style_2 .latest_news_scrolling_list_container .left a,
.style_3 .latest_news_scrolling_list_container .left a
{
	background-image: url("../images/icons/navigation/navigation_latest_arrow_left.png");
}
.style_2 .latest_news_scrolling_list_container .right a,
.style_3 .latest_news_scrolling_list_container .right a
{
	background-image: url("../images/icons/navigation/navigation_latest_arrow_right.png");
}
.style_2 .latest_news_scrolling_list_container li.left:hover, 
.style_2 .latest_news_scrolling_list_container li.right:hover,
.style_2 .search .search_submit:hover
{
	background-color: #E9E9E9;
}
.style_2 .search .search_submit,
.style_3 .search .search_submit
{
	background-image: url("../images/icons/other/header_search.png");
}
/* - style 3 - */
.style_3.header_top_bar_container
{
	background: #F0F0F0;
}
.style_3.border.header_top_bar_container
{
	border-bottom: 1px solid #E0E0E0;
}
.style_3 .latest_news_scrolling_list_container .category,
.style_3 .latest_news_scrolling_list_container li.left, 
.style_3 .latest_news_scrolling_list_container li.right,
.style_3 .search,
.style_3 .search .search_input
{
	border-color: #E0E0E0;
}
.style_3 .latest_news_scrolling_list_container li.left:hover, 
.style_3 .latest_news_scrolling_list_container li.right:hover,
.style_3 .search .search_submit:hover
{
	background-color: #E0E0E0;
}
/* - style 4 - */
.style_4.header_top_bar_container
{
	background: #2D3136;
}
.style_4.border.header_top_bar_container
{
	border-bottom: 1px solid #363B40;
}
.style_4 .latest_news_scrolling_list_container .category,
.style_4 .latest_news_scrolling_list_container li.left, 
.style_4 .latest_news_scrolling_list_container li.right,
.style_4 .search,
.style_4 .search .search_input
{
	border-color: #363B40;
}
.style_4 .latest_news_scrolling_list_container li.left:hover, 
.style_4 .latest_news_scrolling_list_container li.right:hover,
.style_4 .search .search_submit:hover
{
	background-color: #363B40;
}
/* - style 5 - */
.style_5.header_top_bar_container
{
	background: #000;
}
.style_5.border.header_top_bar_container
{
	border-bottom: 1px solid #464D53;
}
.style_5 .latest_news_scrolling_list_container .category,
.style_5 .latest_news_scrolling_list_container li.left, 
.style_5 .latest_news_scrolling_list_container li.right,
.style_5 .search,
.style_5 .search .search_input
{
	border-color: #464D53;
}
.style_5 .latest_news_scrolling_list_container li.left:hover, 
.style_5 .latest_news_scrolling_list_container li.right:hover,
.style_5 .search .search_submit:hover
{
	background-color: #464D53;
}
.style_5 .search .search_input
{
	background-color: transparent;
}
/* --- header container styles --- */
.style_2.header_container
{
	background: #363B40;
}
.style_2 .header h1,
.style_2 .header h1 a,
.style_3 .header h1,
.style_3 .header h1 a
{
	color: #FFF;
}
.style_2 .header h4,
.style_3 .header h4
{
	color: #9DA4AB;
}
.style_3.header_container
{
	background: #000;
}
.small.header_container
{
	padding-bottom: 30px;
}
.small .header
{
	text-align: left;
	padding-top: 22px;
}
.small .header h1
{
	font-size: 68px;
}
.small .header h4
{
	font-size: 16px;
	margin-top: 5px;
}
.small .header .logo
{
	float: left;
}
.small .header .placeholder
{
	display: block;
}
.style_2 .placeholder
{
	background: #42494F;
	color: #858D94;
}
.style_3 .placeholder
{
	background: #2D3136;
	color: #9DA4AB;
}
/* --- page header --- */
.page_header .page_title
{
	font-weight: bold;
}
.page_header_left,
.page_header_right
{
	float: left;
}
.page_header_left
{
	width: 400px;
}
.page_header_right
{	
	width: 650px;
}
/* bread crumbs --- */
.bread_crumb
{
	float: right;
	margin-top: 30px;
}
.bread_crumb li
{
	float: left;
}
.bread_crumb li,
.bread_crumb li a
{
	color: #7C7C7C;
	font-size: 12px;
}
.bread_crumb li a
{
	text-decoration: underline;
}
.bread_crumb li a:hover
{
	color: #25282a;
}
.bread_crumb .separator
{
	background: url("../images/icons/navigation/breadcrumb_arrow.png") no-repeat center center;
	width: 5px;
	height: 13px;
	padding: 0 10px;
}
/* --- footer --- */
.footer_container
{
	background: #363B40;
	padding-top: 50px;
}
.footer
{
	padding-bottom: 30px;
}
.footer p
{
	color: #D7DCE0;
}
.footer a
{
	color: #FFF;
	line-height: 140%;
}
/* --- menu --- */
.menu_container
{
	border-top: 0px solid #E9E9E9;
	border-bottom: 1px solid #E9E9E9;
	background: #FFF;
}
.menu_container.sticky.move
{
	position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
	box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
	-webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
}
.boxed .menu_container.sticky.move
{
	max-width: 1150px;
	width: 100%;
}
.sf-menu
{
	float: none;
	padding: 0;
	margin: -3px 0 0;
}
.sf-menu li
{
	height: 42px;
	border: none;
	border-top: 3px solid #E9E9E9;
	border-bottom: 1px solid #E9E9E9;
	padding-right: 15px;
}
.sf-menu>li
{
	margin-bottom: -1px;
}
.sf-menu li, .sf-menu li:hover, .sf-menu li.sfHover, .sf-menu a:focus, .sf-menu a:hover, .sf-menu a:active
{
	background: none #fff;
}
.sf-menu li a, .sf-menu li a:visited
{
	font-family: 'Roboto';
	font-size: 18px;
	font-weight: 400;
	color: #25282A;
	padding: 10px 0 15px 15px;
	border: none;
}
.sf-menu li.submenu
{
	padding-right: 0;
}
.sf-menu li.submenu a
{
	background-image: url("../images/icons/navigation/menu_arrow.png");
	background-repeat: no-repeat;
	background-position: center right; /* background-position: 18px right; */
	background-color: transparent;
	padding-right: 15px;
	border-right: 15px solid transparent;
}
.sf-menu a:hover
{
	background-color: #F0F0F0;
}
.sf-menu li.submenu ul
{
	background: #363b40;
}
.sf-menu li:hover, .sf-menu li.selected,
.sf-menu li.submenu:hover
{
	background-color: #F0F0F0;
	border-top-color: #ED1C24;
	border-bottom-color: #F0F0F0;
}
.sf-menu li ul
{
	width: 210px;
	margin-top: 1px;
}
.sf-menu li ul.wide li
{
	width: 210px;
}
.sf-menu li.submenu.wide ul
{
	width: 420px;
}
.sf-menu li:hover ul, .sf-menu li.sfHover ul
{
	top: 41px;
}
.sf-menu li ul li
{
	width: 210px;
	height: auto;
	padding: 0;
	border: none;
}
.menu_container .sf-menu li:hover ul a,
.menu_container .sf-menu li.submenu:hover ul a,
.menu_container .sf-menu li ul li a,
.menu_container .sf-menu li.submenu:hover ul li.selected ul li a
{
	border: none;
	color: #D7DCE0;
	background-color: #363B40;
}
.menu_container .sf-menu li ul li a,
.menu_container .sf-menu li.selected ul li a,
.menu_container .sf-menu li.submenu ul li a,
.menu_container .sf-menu li.submenu:hover ul li a,
.menu_container .sf-menu li:hover ul li a
{
	padding: 15px;
	font-family: 'Roboto';
	font-size: 14px;
	color: #D7DCE0;
	background-image: none;
}
.menu_container .sf-menu li ul li.submenu>a,
.menu_container .sf-menu li:hover ul li.submenu>a
{
	background-image: url("../images/icons/navigation/dark_bg/submenu_arrow.png");
	background-repeat: no-repeat;
	background-position: center right;
	padding-right: 0;
	border-right: 15px solid transparent;
}
.menu_container .sf-menu li ul li.submenu>a:hover,
.menu_container .sf-menu li ul li.submenu.sfHover>a
{
	background-image: url("../images/icons/navigation/dark_bg/submenu_arrow_hover.png");
}
.menu_container .sf-menu li ul li a:hover, .menu_container .sf-menu li ul li.selected a,
.menu_container .sf-menu li.submenu ul li a:hover, .menu_container .sf-menu li.submenu:hover ul li.selected a,
.menu_container .sf-menu li.submenu:hover ul li.selected ul li a:hover, .menu_container .sf-menu li.submenu:hover ul li ul li.selected a, .menu_container .sf-menu li.submenu:hover ul li.selected ul li.selected a,
.menu_container .sf-menu li:hover ul li.sfHover>a
{	
	background-color: #42494F;
	color: #FFF;
}
.sf-menu li.submenu .mega_menu
{
	position: absolute;
	top: -999em;
	background: #42494f;
	padding: 30px 0px 30px 30px;
}
ul.sf-menu li li:hover ul, ul.sf-menu li li.sfHover ul,
ul.sf-menu li li li:hover ul, ul.sf-menu li li li.sfHover ul,
ul.sf-menu li li:hover .mega_menu, ul.sf-menu li li.sfHover  .mega_menu,
ul.sf-menu li li li:hover  .mega_menu, ul.sf-menu li li li.sfHover  .mega_menu
{
	left: 210px;
	top: -1px;
	z-index: 2 !important;
}
ul.sf-menu li:hover li .mega_menu
{
	display: none;
	width: 810px;
	left: 210px;
}
ul.sf-menu .mega_menu,
ul.sf-menu .mega_menu li
{
	background-color: #42494F;
}
/* --- mobile menu --- */
.mobile_menu_container
{
	display: none;
}
.mobile-menu-switch,
.mobile-menu,
.mobile-menu-divider
{
	display: none;
	margin-left: auto;
	margin-right: auto;
}
.mobile-menu-divider
{
	margin-top: 15px;
}
.mobile-menu li a
{
	display: block;
	color: #25282A;
	background: #F0F0F0;
	font-size: 16px;
	font-family: "Roboto";
	padding: 12px 20px;
	margin-bottom: 1px;
}
.mobile-menu li.selected a,
.mobile-menu li.selected ul li.selected a,
.mobile-menu li.selected ul li.selected ul li.selected a
{
	background: #ED1C24;
	color: #FFF;
}
.mobile-menu li ul a
{
	font-size: 14px;
	padding-left: 40px;
}
.mobile-menu li ul ul a
{
	font-size: 13px;
	padding-left: 80px;
}
.mobile-menu li.selected ul a,
.mobile-menu li.selected ul li.selected ul a
{
	color: #25282A;
	background: #F0F0F0;
}
.mobile-menu-switch
{
	width: 35px;
	padding: 15px 8px 9px;
	border: 2px solid #ED1C24;
}
.mobile-menu-switch .line
{
	display: block;
	width: 35px;
	height: 3px;
	background: #ED1C24;
	margin-bottom: 6px;
}
.mobile-menu-switch:hover
{
	background: #ED1C24;
}
.mobile-menu-switch:hover .line
{
	background: #FFF;
}
/* --- footer menu ---*/
.footer_menu li
{
	float: left;
	margin-right: 30px;
}
/* --- page --- */
.page
{
	width: 1050px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 50px;
}
.page_margin_top
{
	margin-top: 30px;
}
.page_margin_top_section
{
	margin-top: 50px;
}
/* --- vertical align --- */
.vertical_align
{
	display: table-row;
}
.vertical_align_cell
{
	display: table-cell;
	vertical-align: middle;
}
/* --- slider --- */
.caroufredsel_wrapper
{
	width: 100% !important;
	margin: 0 !important;
	overflow: hidden;
}
.caroufredsel_wrapper_slider
{
	margin-top: 30px !important;
}
.slider
{
	/*position: absolute;*/
	padding: 0;
	width: 1250px;
	height: 550px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 0;
   
}
.slider li.slide
{
	float: left;
	position: relative;
	width: 1250px;
	height: 550px;
	background-position: top center;
	background-repeat: no-repeat;
	margin-right: 10px;
}
.slider li.slide img
{
	width: 1250px;
}
.slider li.slide .pr_preloader
{
	height: 550px;
}
/* --- small slider --- */
.small_slider
{
	display: none;
}
.small_slider li.slide
{
	position: relative;
	float: left;
	width: 690px;
}
.small_slider li.slide img
{
	display: block;
	opacity: 1;
	transition: opacity 0.4s ease-in-out 0s;
	height: auto;
}
.small_slider li.slide a:hover img
{
	opacity: 0.8;
	transition: opacity 0.4s ease-in-out 0s;
}
.small_slider .slider_content_box
{
	width: 650px;
	left: 0;
	bottom: 0;
	top: auto;
	/*background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.8) 100%);*/
}
/* --- slider content --- */
.slider_content_box
{
	position: absolute;
	width: 350px;
	padding: 18px 20px 20px;
	background: rgba(0,0,0,0.5);
	left: 105px;
	top: 260px;
}
.slider_content_box h2,
.slider_content_box h5
{
	float: left;
	clear: both;
	color: #FFF;
	margin-top: 17px;
}
.slider_content_box h2 a,
.slider_content_box h5 a
{
	color: #FFF;
}
.slider_content_box .post_details li.category:after
{
	background: none repeat scroll 0 0 #FFF;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    margin-top: 13px;
    width: 30px;
}
.slider_content_box p
{
	clear: both;
	color: #FFF;
	font-size: 14px;
	padding: 18px 0 0 0;
}
/* --- slider navigation --- */
.slider_navigation
{
	position: absolute;
	top: 0;
	right: 0;
}
.slider_navigation .slider_control
{
	float: left;
}
.slider_navigation .slider_control a,
a.slider_control
{
	display: block;
	width: 45px;
	height: 45px;
	background-color: rgba(66,73,79,0.8);
	background-image: url("../images/icons/navigation/navigation_arrow_right.png");
	background-position: 0 0;
}
.slider_navigation .slider_control a
{
	background-color: rgba(0,0,0,0.5);
}
a.slider_control
{
	display: block !important;
	/*display: none;*/
	visibility: hidden;
	position: absolute;
	z-index: 3;
	bottom: 0;
	-webkit-animation-duration: 300ms;
    animation-duration: 300ms;
}
a.slider_control.right
{
	right: 0;
}
.slider_navigation .slider_control:first-child a,
a.slider_control.left
{
	background-image: url("../images/icons/navigation/navigation_arrow_left.png");
}
a.slider_control.up
{
	background-image: url("../images/icons/navigation/navigation_arrow_up.png");
	top: 30px;
}
a.slider_control.down
{
	background-image: url("../images/icons/navigation/navigation_arrow_down.png");
}
a.slider_control.up,
a.slider_control.down
{
	left: 55px;
}
.slider_navigation .slider_control a:hover,
a.slider_control:hover
{
	background-color: #ED1C24;
}
/* --- slider posts list --- */
.slider_posts_list_container
{
	position: relative;
}
.slider_posts_list
{
	clear: both;
}
.slider_posts_list li
{
	float: left;
	position: relative;
	width: 262.5px !important;
	height: 120px;
	border-bottom: 1px solid #F0F0F0;
	cursor: pointer;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.column_2_3 .slider_posts_list li
{
	width: 230px !important;
}
.slider_posts_list li.current h5, .slider_posts_list li:hover h5
{
	color: #25282A;
}
.slider_posts_list li h5
{
	position: relative;
	z-index: 1;
	padding-top: 8px;
	padding-left: 20px;
	padding-right: 20px;
	color: #7C7C7C;
}
.slider_posts_list li .date
{
	display: block;
	position: relative;
	z-index: 1;
	color: #ABABAB;
	font-size: 11px;
	padding-top: 23px;
	padding-left: 20px;
}
.slider_posts_list .slider_posts_list_bar
{
	position: absolute;
	background-color: #ED1C24;
    height: 6px;
	bottom: -1px;
}
.slider_posts_list .slider_posts_list_progress_block
{
	position: absolute;
	background-color: #F0F0F0;
    height: 100%;
	bottom: 4px;
}
/* --- blog grid --- */
.blog_grid .post
{
	position: relative;
	float: left;
	clear: none;
	margin: 0 0 0 2px;
	width: 524px;
	height: 261px;
}
.blog_grid .post .pr_preloader
{
	width: 524px;
	height: 261px;
}
.blog_grid .post.large,
.blog_grid .post.large .pr_preloader
{
	width: 787px;
	height: 524px;
}
.blog_grid .post.big,
.blog_grid .post.big .pr_preloader
{
	height: 524px;
}
.blog_grid .post.small,
.blog_grid .post.small .pr_preloader
{
	width: 261px;
}
.blog_grid .post:first-child
{
	margin: 0;
}
.blog_grid  .row
{
	margin-top: 2px;
}
.blog_grid  .row:first-child
{
	margin-top: 0;
}
.blog_grid  .column
{
	margin-left: 2px;
}
.site_container.boxed .blog_grid .post .slider_content_box,
.blog_grid .post .slider_content_box
{
	width: 100%;
	left: 0;
	bottom: 0;
	top: auto;
	background: linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,rgba(0,0,0,0.4)));
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
	background: -o-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
	background: -ms-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0);
	-ms-filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#66000000',GradientType=0);
	cursor: pointer;
	padding: 40px 0 20px;
}
.blog_grid .post .post_details.simple
{
	margin: 0 20px;
}
.blog_grid .post h2,
.blog_grid .post h5
{
	margin: 17px 20px 0;
}
.blog_grid .post:hover img
{
	opacity: 0.8;
	transition: opacity 0.4s ease-in-out 0s;
}
.blog_grid .slider_view
{
	display: none;
}
/* --- page layout --- */
.row:after
{
    font-size: 0px;
    content: ".";
    display: block;
    height: 0px;
    visibility: hidden;
    clear: both;
}
.column 
{
	float: left;
	margin-left: 30px;
}
.column:first-child,
.column.first
{
	margin-left: 0;
}
.column.border_top
{
	border-top: 1px solid #E9E9E9;
}
.column_1_1
{
	width: 100%;
}
.column_1_2
{
	width: 510px;
}
.column_1_3,
.column_2_3 .column_1_2
{
	width: 330px;
}
.column_2_3
{
	width: 690px;
}
.column_1_3 .column_1_2
{
	width: 150px;
}
.column_2_3 .column_1_3
{
	width: 210px;
}
.column_1_4
{
	width: 240px;
}
.column_3_4
{
	width: 780px;
}
.row.copyright_row
{
	border-top: 1px solid #464D53;
	margin-top: 15px;
	padding-top: 15px;
}
.column_right
{
	float: right;
}
.columns.no_width .column_left,
.columns.no_width .column_right
{
	width: auto;
}
.mega_menu .column_1_2
{
	width: 390px;
}
.mega_menu .column_1_3
{
	width: 250px;
}
/* --- blog --- */
.sf-menu li.submenu .mega_menu.blog
{
	padding: 0 30px 30px 0;
}
.mega_menu  li.post
{
	float: left;
	margin: 30px 0 0 30px;
	margin-bottom: 0;
}
.post
{
	float: left;
	clear: both;
	margin-top: 50px;
}
.post:first-child
{
	margin-top: 30px;
}
.post img
{
	position: relative;
	display: block;
	width: 100%;
	opacity: 1;
	transition: opacity 0.4s ease-in-out 0s;
}
.post a:hover img
{
	opacity: 0.8;
	transition: opacity 0.4s ease-in-out 0s;
}
.post h2
{
	clear: both;
	margin-top: 20px;
}
.post .with_number a
{
	float: left;
	width: 280px;
}
.post a.comments_number
{
	float: right;
	position: relative;
	width: auto;
	font-family: 'Arial';
	font-weight: bold;
	background: #F0F0F0;
	color: #7C7C7C;
	font-size: 12px;
	line-height: 1;
	padding: 8px 11px 9px;
	margin-top: 6px;
}
.footer .post a.comments_number
{
	background: #42494F;
	color: #9DA4AB;
}
.post .comments_number:hover,
.footer .post .comments_number:hover
{
	background: #ED1C24;
	color: #FFF;
}
.post .arrow_comments
{
	content: '';
	display: inline;
	position: absolute;
	border-style: solid;
	border-width: 5px 0 0 5px;
	border-color: #F0F0F0 transparent;
	right: 0;
	bottom: -5px;
}
.footer .post .arrow_comments
{
	border-color: #42494F transparent;
}
.post .comments_number:hover .arrow_comments,
.footer .post .comments_number:hover .arrow_comments
{
	border-color: #ED1C24 transparent;
}
.post h5,
.menu_container .sf-menu li ul.mega_menu li.post h5 a
{
	clear: both;
	color: #FFF;
	margin-top: 15px;
}
.post h5.page_margin_top_section
{
	margin-top: 50px;
}
.sf-menu .mega_menu li.post:hover,
.sf-menu .mega_menu li.post li:hover
{
	background: transparent;
}
.menu_container .sf-menu li ul.mega_menu li.post a
{
	background: none;
	padding: 0;
}
.sf-menu .mega_menu li.post h5 a:hover
{
	text-decoration: underline;
}
span.number,
span.odometer.number
{
	color: #ababab;
    font-family: 'Roboto Condensed';
    font-size: 30px;
    font-weight: 300;
    padding: 5px 8px 5px 0;
	visibility: visible;
}
.post h5 .number
{
	float: left;
}
.post p
{
	margin-top: 11px;
}
.post .post_details
{
	clear: both;
	float: left;
	margin-top: 25px;
}
.post .post_details+p
{
	clear: both;
	float: left;
}
.post_details li
{
	float: left;
	font-family: arial;
	font-size: 12px;
}
.post_details li.category
{
	font-weight: bold;
	background: #ED1C24;
	padding: 14px 15px 13px 14px;
}
.post_details li.category a
{
	color: #FFF;
}
.post_details li.date
{
	color: #7C7C7C;
	border: 1px solid #E9E9E9;
	border-left: none;
	padding: 13px 14px 12px 13px;
}
.menu_container .mega_menu  li.post
{
	clear: none;
	width: 240px;
}
.post ul.post_details.simple
{
	position: static;
	background: none;
	margin-top: 14px;
}
ul.post_details.simple li
{
	background: none;
	padding: 0;
}
.post ul.post_details.simple li.date,
ul.post_details.simple li.date
{
	margin-left: 10px;
	border: none;
}
.mega_menu li.post ul.post_details.simple li
{
	width: auto;
	color: #9DA4AB;
}
.slider_content_box ul.post_details li
{
	color: #FFF;
}
.blog  ul.post_details.simple li.category,
.blog  ul.post_details.simple li.category a
{
	color: #ED1C24;
}
.mega_menu.blog  ul.post_details.simple li.category a,
.menu_container .mega_menu .blog.small .post li.category,
.menu_container .mega_menu .blog.small .post li.category a
{
	font-family: arial;
	color: #9da4ab;
	font-size: 12px;
	font-weight: bold;
}
.mega_menu.blog  ul.post_details.simple li.category a:hover
{
	text-decoration: underline;
}
.mega_menu .column_1_2 .blog.small .post
{
	width: 390px;
}
.mega_menu .column_1_3 .blog.small .post
{
	width: 250px;
}
.mega_menu .column_1_2 .blog.small .post_content
{
	width: 250px;
}
.mega_menu .column_1_3 .blog.small .post_content
{
	width: 130px;
}
.mega_menu .column_1_3 .blog.small .post ul.post_details.simple li.date
{
	clear: both;
	margin-left: 0;
	margin-top: 5px;
}
.mega_menu .blog.small
{
	position: static;
	left: 0;
	top: 0;
	margin-top: 0;
}
.mega_menu .blog.small .post
{
	margin: 30px 0 0;
}
.mega_menu .blog.small .post:first-child
{
	margin-top: 0;
}
.menu_container .mega_menu .blog.small .post a
{
	padding: 0;
	background: none;
	color: #FFF;
}
.blog.small .post,
.blog.small_margin .post
{
	margin-top: 30px;
}
.blog.big img
{
	width: 330px;
}
.blog.small img
{
	width: auto;
}
.blog.big img,
.blog.big .post_content,
.blog.small img,
.blog.small .post_content
{
	float: left;
}
.blog.big .post_content
{
	width: 330px;
	margin-left: 30px;
}
.blog.small .post_content
{
	margin-left: 20px;
}
.column_2_3 .column_1_2 .blog.small .post_content,
.column_1_3 .blog.small .post_content
{
	width: 210px;
}
.column_1_2 .blog.small .post_content
{
	width: 390px;
}
.blog.small .post_content p
{
	margin-top: 0;
}
.blog.small .post_details.simple
{
	clear: none;
}
.blog.big .post_content h2,
.blog.small .post_content h5
{
	margin-top: 0;
}
.blog.medium .post
{
	clear: none;
	width: 210px;
	margin-top: 30px;
	margin-left: 30px;
}
.blog.medium .post:first-child,
.blog.medium .post:nth-child(3n+1)
{
	margin-left: 0;
}
.blog.rating .post
{
	width: 100%;
	margin-top: 10px;
	padding-bottom: 20px;
}
.blog.rating .post:first-child
{
	margin-top: 0;
}
.blog.rating .post,
.blog.rating .post_content
{
	position: relative;
	z-index: 1;
}
.blog.rating .post_content
{
	padding: 10px 20px;
}
.blog.rating .post span.number
{
	display: block;
	padding: 5px 0 0;
}
.blog.rating h5
{
	margin-top: 5px;
}
.blog.rating .post .value_bar_container
{
	position: absolute;
	bottom: 0;
}
.blog.rating .post .value_bar
{
	background: #F0F0F0;
	height: 100%;
}
.footer .blog ul.post_details.simple li.category,
.footer .blog ul.post_details.simple li.category a,
.footer .post_details li.date
{
	color: #9DA4AB;
	line-height: 1;
}
.column_1_3 .blog.small .post
{
	width: 330px;
}
/* --- single post --- */
h1.post_title
{
	font-size: 55px;
	font-weight: bold;
}
.post.single
{
	margin-top: 0;
}
.post.single .post_image
{
	display: block;
	position: relative
}
.post.single .post_image blockquote
{
	position: absolute;
	bottom: 0;
	background-image: url("../images/icons/other/quote_image.png");
	background-color: rgba(0,0,0,0.5);
	background-position: 20px 20px;
	color: #FFF;
}
.post.single .post_image blockquote .author
{
	color: #FFF;
}
.post.single .sentence,
.gallery_popup .sentence
{
	margin-top: 20px;
}
.post.single .sentence .text,
.gallery_popup .sentence .text
{
	color: #7C7C7C;
	font-weight: bold;
}
.post.single .sentence .author,
.gallery_popup .sentence .author,
blockquote .author
{
	color: #ABABAB;
	margin-left: 3px;
}
.gallery_popup .sentence .author
{
	color: 858D94;
	margin-left: 0;
}
.post.single .sentence .text,
.post.single .sentence .author,
.gallery_popup .sentence .text,
.gallery_popup .sentence .author,
blockquote .author
{
	line-height: 120%;
	font-size: 12px;
}
.post.single .post_content
{
	position: relative;
}
.post.single .author_box,
.post.single .content_box
{
	float: right;
}
.post.single .author_box
{
	position: absolute;
	width: 100px;
	text-align: center;
	visibility: visible;
	top: 0;
}
.post.single .content_box
{
	width: 560px;
	margin-left: 30px;
}
.post.single .text
{	
	font-size: 16px;
}
.post.single blockquote.inside_text
{
	float: left;
	width: 200px;
	margin-bottom: 1em;
	font-size: 18px;
	padding-top: 60px;
	background-position: 20px 30px;
	line-height: 140%;
	margin-right: 30px;
}
.post.single blockquote.right
{
	float: right;
	margin-right: 0;
	margin-left: 30px;
}
.post.single blockquote.inside_text.simple
{
	background-position: 0 20px;
}
blockquote .author
{
	display: block;
	margin-top: 23px;
	font-weight: normal;
}
.post.single .post_details
{	
	clear: both;
	float: none;
	margin-top: 20px;
	padding-top: 15px;
	border-top: 1px solid #E9E9E9;
}
.post.single .post_details a
{
	color: #ED1C24;
}
.post.single li.detail
{
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: left center;
	border: none;
	padding: 0 0 0 20px;
	margin-right: 20px;
	margin-bottom: 5px;
	font-size: 13px;
	font-weight: bold;
	color: #7C7C7C;
}
li.detail.category
{
	background-image: url("../images/icons/other/post_category.png");
}	
.detail.date
{
	background-image: url("../images/icons/other/post_date.png");
}
.detail.author
{
	background-image: url("../images/icons/other/post_author.png");
}
.detail.views
{
	background-image: url("../images/icons/other/post_views.png");
}
.detail.comments
{
	background-image: url("../images/icons/other/post_comments.png");
}
.share_box
{
	border-top: 1px solid #E9E9E9;
	border-bottom: 1px solid #E9E9E9;
	padding: 20px 0;
}
.share_box label
{
	float: left;
	font-size: 18px;
	margin-top: 5px;
}
.share_box .social_icons
{
	float: right;
}
.taxonomies.tags.left
{
	float: left;
}
.taxonomies.categories.right
{
	float: right;
}
.column_2_3 .iframe_video
{
	width: 100%;
	height: 388px
}
.column_2_3 .iframe_sound
{
	width: 100%;
}
/* --- single post small image --- */
.post.single.small_image .post_image_box
{
	float: left;
	width: 330px;
	margin-right: 30px;
}
.post.single.small_image .post_image_box .pr_preloader
{
	height: 242px;
}
.post.single.small_image .content_box
{
	width: 100%;
}
.post.single.small_image .text
{
	display: inline;
}
.post.single.small_image .text>*
{
	margin-left: 130px;
}
.post.single.small_image p
{
	clear: none;
	float: none;
}
/* --- list --- */
.list
{
	margin-top: 20px;
	border-top: 1px solid #E9E9E9;
}
.list li ul
{
	margin-top: 0;
}
.list li
{
	padding-top: 6px;
	padding-bottom: 6px;
}
.list li:first-child
{
	margin-top: 14px;
}
.list li ul li:first-child
{
	margin-top: 4px;
}
.list.spacing li ul li:first-child
{
	margin-top: 7px;
}
.list li,
.list li a
{
	color: #25282A;
}
.list.indent
{
	padding-left: 15px;
}
.list.no_border,
.review_block .list
{
	border-top: none;
}
.list.spacing li,
.review_block .list li
{
	padding-top: 7px;
	padding-bottom: 7px;
	margin-top: 0;
}
.review_block .list li,
.review_block .list li a
{
	color: #3E3E3E;
}
/* --- dropcap --- */
.dropcap
{
	line-height: 150%;
}
.dropcap .dropcap_label
{
	float: left;
	padding: 6px 13px;
	background-color: #F0F0F0;
	text-align: center;
	margin-right: 15px;
	margin-bottom: 5px;
}
.dropcap .dropcap_label.active
{
	background-color: #ED1C24;
}
.dropcap .dropcap_label h3
{
	color: #25282A;
	font-size: 24px;
}
.dropcap .dropcap_label.active h3
{
	color: #FFF;
}
/* --- taxonomies --- */
.taxonomies li
{
	float: left;
	margin-right: 5px;
	margin-bottom: 5px;
}
.column_1_3  .taxonomies
{
	width: 335px;
}
.column_1_3 .taxonomies.columns li
{
	width: 48.51%;
}
.taxonomies a
{	
	display: block;
	border: 1px solid #E9E9E9;
	color: #7C7C7C; 
	font-family: 'Arial';
	font-size: 12px;
	font-weight: bold;
	padding: 13px 12px 12px;
	text-align: left;
}
.taxonomies a:hover
{
	color: #FFF;
	background: #ED1C24;
	border-color: #ED1C24;
}
.taxonomies.columns
{
	text-align: center;
}
.taxonomies.tags
{
	padding-left: 35px;
	background: url("../images/icons/other/post_footer_tags.png") no-repeat left center;
}
.taxonomies.categories
{
	padding-left: 35px;
	background: url("../images/icons/other/post_footer_category.png") no-repeat left center;
}
/* --- review --- */
.review_block
{
	padding: 30px;
	background: #F0F0F0;
}
.review_summary
{	
	position: relative;
	background: transparent;
	padding: 30px 0;
}
.review_block h5,
.review_summary h5
{	
	color: #25282A;
}
.column_2_3 .review_block  .column_1_2
{
	width: 300px;
}
.review_chart li
{
	margin-top: 15px;
}
.review_chart li:first-child
{
	margin-top: 0;
}
.review_summary .number,
.review_summary .text
{
	position: relative;
	float: left;
	z-index: 1;
}
.review_summary .text
{
	width: 480px;
}
.review_summary .text p
{
	color: #3E3E3E;
	font-size: 14px;
	margin-top: 0;
}
.review_summary .number
{
	border: 2px solid #ED1C24;
	color: #ED1C24;
	font-size: 42px;
	font-weight: 300;
	font-family: 'Roboto Condensed';
	padding: 27px 23px;
	margin: 0 30px;
}
.review_summary .value_bar_container
{
	position: absolute;
	bottom: 0;
}
.review_summary .value_bar
{
	background: #F0F0F0;
	height: 100%;
}
/* --- value bar --- */
.value_container
{
	background: #FFF;
	height: 26px;
	margin-top: 10px;
}
.value_container .value_bar
{
	position: relative;
	height: 20px;
	padding-top: 6px;
	background: #ED1C24;
	text-align: right;
}
.value_container .value_bar .number
{
	margin: 0 10px;
	padding: 0;
	color: #FFF;
	font-size: 13px;
	font-weight: bold;
	font-family: arial;
}
/* --- announcement --- */
.announcement
{
	background: #F0F0F0;
	padding: 30px;
}
.announcement h2
{
	float: left;
	margin-right: 10px;
}
.announcement .expose
{
	color: #ED1C24;
	margin-right: 0;
}
.announcement .vertical_align
{
	height: 57px;
}
/* --- read more --- */
.read_more
{
	position: relative;
	display: block;
	height: 29px;
	clear: both;
	float: left;
	color: #25282A;
	font-size: 12px;
	font-weight: bold;
	margin-top: 10px;
	padding-right: 12px;
}
.read_more.page_margin_top
{
	margin-top: 30px;
}
.read_more.page_margin_top_section
{
	margin-top: 50px;
}
.read_more span
{
	display: block;
	line-height: normal;
	margin-top: 7px;
	position: relative;
	margin-left: 38px;
}
.read_more .arrow
{
	position: absolute;
	width: 29px;
	height: 29px;
	margin-left: 0;
	background: #ED1C24 url("../images/icons/navigation/call_to_action_arrow.png") no-repeat;
	margin-top: 0;
}
.read_more:hover
{
	color: #FFF;
}
.read_more:hover .arrow
{
	width: 100%;
}
/* --- comment form, contact form --- */
.comment_form fieldset,
.contact_form fieldset
{
	clear: both;
}
.comment_form fieldset.column,
.contact_form fieldset.column
{
	clear: none;
}
.comment_form fieldset.column_1_3,
.contact_form fieldset.column_1_3
{
	margin-left: 10px;
	width: 223px;
}
.comment_form fieldset.column_1_3:first-child,
.contact_form fieldset.column_1_3:first-child
{
	width: 224px;
}
.column_1_3 .comment_form fieldset.column_1_3,
.column_1_3 .contact_form fieldset.column_1_3,
.comment_form fieldset.column:first-child,
.contact_form fieldset.column:first-child
{
	margin-left: 0;
}
.column_1_3 .comment_form fieldset.column_1_3,
.column_1_3 .contact_form fieldset.column_1_3
{
	margin-top: 10px;
}
.column_1_3 .comment_form fieldset.column_1_3:first-child,
.column_1_3 .contact_form fieldset.column_1_3:first-child
{
	margin-top: 0;
}
.comment_form input,
.comment_form textarea,
.contact_form input,
.contact_form textarea
{
	background: #F0F0F0;
	border: none;
	font-size: 14px;
	padding: 15px 20px;
	color: #3E3E3E;
}
.comment_form textarea,
.contact_form textarea
{
	width: 650px;
	height: 105px;
	margin-top: 10px;
	resize: none;
}
.comment_form .text_input,
.contact_form .text_input
{
	width: 183px;
}
.comment_form fieldset.column:first-child .text_input,
.contact_form fieldset.column:first-child .text_input
{
	width: 184px;
}
.column_1_3 .comment_form .text_input,
.column_1_3 .contact_form .text_input,
.column_1_3 .comment_form fieldset.column:first-child .text_input,
.column_1_3 .contact_form fieldset.column:first-child .text_input,
.column_1_3 .comment_form textarea,
.column_1_3 .contact_form textarea
{
	width: 290px;
}
.comment_form [type='submit'],
.contact_form [type='submit']
{
	float: right;
	font-size: 12px;
	color: #25282a;
	margin-top: 10px;
	outline: none;
}
.comment_form .text_input:focus,
.comment_form textarea:focus,
.contact_form .text_input:focus,
.contact_form textarea:focus
{
	background: transparent;
	border-bottom: 1px solid #E9E9E9;
	padding-bottom: 14px;
}
.comment_form textarea:focus,
.contact_form textarea:focus
{
	padding: 14px 19px;
	border: 1px solid #E9E9E9;
}
#cancel_comment
{
	display: none;
	float: right;
	font-size: 11px;
	margin-top: 30px;
	margin-right: 20px;
	color: #ED1C24;
}
.comment_form .hint,
.contact_form .hint
{
	color: #ababab;
}
::-webkit-input-placeholder 
{
	color: #ababab;
}
:-moz-placeholder 
{
	color: #ababab;
    opacity:  1;
}
::-moz-placeholder
{
	color: #ababab;
    opacity:  1;
}
:-ms-input-placeholder 
{
	color: #ababab;
}
/* --- search form --- */
.search_form input[type='text']
{
	width: 650px;
}
.search_form input[type='text']:focus
{
	background: transparent;
	border-bottom: 1px solid #E9E9E9;
	padding-bottom: 14px;
}
.search_form input[type='submit']
{
	float: right;
}
/* --- tooltip --- */
.ui-tooltip-error .ui-tooltip-content,
.ui-tooltip-success .ui-tooltip-content
{
	font-size: 13px;
	font-family: arial;
	color: #FFF;
}
.ui-tooltip-error .ui-tooltip-content,
.ui-tooltip-success .ui-tooltip-content
{
	border:none; 
}
.ui-tooltip-content
{
	padding: 10px 20px 10px 20px;
}
.ui-tooltip-error .ui-tooltip-content
{
	background: #E9431C;
}
.ui-tooltip-success .ui-tooltip-content
{
	background: #61911B;
}
/* --- comments list --- */
.comment
{
	margin-top: 50px;
}
#comments_list>.comment:first-child
{
	margin-top: 30px;
}
.comment_author_avatar
{
    float: left;
	width: 100px;
    height: 100px;
	background: url("../images/samples/100x100/avatar.png");
}
.posted_by
{
	clear: both;
}
.posted_by h5
{
	float: left;
}
.posted_by abbr.timeago
{
	float: right;
	color: #7C7C7C;
	font-size: 12px;
	margin-top: 7px;
	text-decoration: none;
}
.posted_by .in_reply
{
	margin-left: 5px;
	color: #ABABAB;
}
#comments_list .comment_details
{
	float: left;
	width: 560px;
	margin-left: 30px;
}
#comments_list .children 
{
    clear: both;
    float: left;
    margin-left: 70px;
}
#comments_list .children .comment_details
{
	width: 460px;
}
#comments_list .children  .comment .parent_arrow
{
	float: left;
	width: 10px;
	height: 16px;
	margin-right: 20px;
	background: url("../images/icons/other/comment_reply.png") no-repeat;
}
/* --- pagination --- */
.pagination
{
	clear: both;
}
.pagination li
{
	float: left;
	margin-left: 5px;
}
.pagination li:first-child
{
	margin-left: 0;
}
.pagination li.left,
.pagination li.right
{
	width: 29px;
}
.pagination li.left a,
.pagination li.right a
{
	background-repeat: no-repeat;
	background-position: 0 0;
}
.pagination li.left a
{
	background-image: url("../images/icons/navigation/pagination_arrow_left.png");
}
.pagination li.right a
{
	background-image: url("../images/icons/navigation/pagination_arrow_right.png");
}
.pagination li.left a:hover,
.pagination li.right a:hover
{
	background-position: 0 -29px;
}
.pagination li a
{
	display: block;
    color: #7C7C7C;
    font-size: 14px;
    padding: 8px 10px 7px 11px;
	background: #F0F0F0;
}
.pagination li a:hover,
.pagination li.selected a
{
	color: #FFF;
	background-color: #ED1C24;
}
/* --- list bullet --- */
.bullet
{
	background-repeat: no-repeat;
	background-position: left center;
	padding-left: 25px;
	list-style-type: none;
}
.bullet.parent
{
	background-position: left 3px;
}
.spacing .bullet.parent
{
	background-position: left 6px;
}
.bullet.style_1
{
	background-image: url("../images/icons/other/bullet_style_1.png");
	padding-left: 15px;
}
.bullet.style_2
{
	background-image: url("../images/icons/other/bullet_style_2.png");
}
.bullet.style_3
{
	background-image: url("../images/icons/other/bullet_style_3.png");
}
.bullet.style_4
{
	background-image: url("../images/icons/other/bullet_style_4.png");
}
/* --- tabs navigation --- */
.tabs_navigation
{
	width: 100%;
	padding: 0;
	margin: 0 0 -15px;
	list-style: none;
	box-sizing: content-box;
	-moz-box-sizing: content-box;
	-webkit-box-sizing: content-box;
	background: none;
	border: none;
}
.tabs_navigation.small
{
	border-bottom: 1px solid #e9e9e9;
	margin: 0;
}
.tabs_navigation li
{
	position: relative;
	float: left;
	padding: 0;
	border: none;
	list-style: none;
	text-align: center;
}
.tabs_navigation li a
{
	position: relative;
	width: auto;
	display: block;
	text-align: center;
	font-size: 18px;
	color: #25282A;
	padding: 8px 15px 11px;
	border: none;
	background: #F0F0F0;
	font-family: 'Roboto';
	line-height: normal;
	outline: none;
	text-decoration: none;
	transition: none;
	line-height: 140%;
	border-right: 1px solid #E0E0E0;
}
.tabs_navigation li:last-child a
{
	border-right: none;
}
.tabs_navigation li a:hover,
.tabs_navigation li a.selected,
.tabs_navigation li.ui-tabs-active a
{
	background: #ED1C24;
	color: #FFF;
}
.tabs_navigation li.ui-tabs-active span
{
	display: inline;
	position: relative;
	border-style: solid;
	border-width: 9px 9px 0;
	border-color: #ED1C24 transparent;
	bottom: -9px;
}
.tabs_navigation.small li
{
	margin: 5px 20px -1px 0;
}
.tabs_navigation.small li:last-child
{
	margin-right: 0;
}
.tabs_navigation.small li a
{
	padding: 0px 0px 8px 0;
	font-size: 15px;
	margin-bottom: 1px;
	color: #7C7C7C;
	background: none;
	border-right: none;
}
.tabs_navigation.small li a:hover,
.tabs_navigation.small li a.selected,
.tabs_navigation.small li.ui-tabs-active a
{
	border-bottom: 3px solid #ED1C24;
	padding-bottom: 6px;
	margin-bottom: 0;
	background: none;
	color: #25282A;
}
/* --- accordion --- */
.accordion.medium .ui-accordion-content
{
	padding: 30px 0 20px;
}
.accordion .ui-accordion-header
{
	padding: 10px 12px;
	cursor: pointer;
	border-bottom: 1px solid #E9E9E9;
}
.accordion .ui-accordion-header.ui-state-active
{
	background-color: #ED1C24;
	border-bottom-color: #ED1C24;
}
.accordion .ui-accordion-header h4
{
	padding-left: 41px;
}
.accordion .ui-accordion-header.ui-state-active h4
{
	color: #FFF;
	line-height: normal;
}
.accordion  .ui-accordion-header .ui-accordion-header-icon
{
	float: left;
	width: 21px;
	height: 21px;
	background: #F0F0F0 url("../images/icons/navigation/accordion_arrow_down.png") no-repeat 0 0;
	margin-right: 20px;
	margin-top: 2px;
}
.accordion  .ui-accordion-header:hover .ui-accordion-header-icon
{
	background: #ED1C24 url("../images/icons/navigation/accordion_arrow_down_hover.png") no-repeat 0 0;
}
.accordion .ui-accordion-header.ui-state-active .ui-accordion-header-icon
{
	background: transparent url("../images/icons/navigation/accordion_arrow_up.png") no-repeat 0 0;
}
/* --- carousel --- */
.horizontal_carousel
{
	display: none;
}
.horizontal_carousel_container,
.vertical_carousel_container
{
	position: relative;
}
.horizontal_carousel_container a.slider_control
{
	top: 109px;
}
.horizontal_carousel_container.big a.slider_control,
.column_1_1 .horizontal_carousel_container.big a.slider_control
{
	top: 197px;
}
.column_2_3 .horizontal_carousel_container.big a.slider_control
{
	top: 405px;
}
.column_1_1 .horizontal_carousel_container a.slider_control
{
	top: 131px;
}
.column_1_1 .horizontal_carousel_container.small a.slider_control
{
	top: 76px;
}
.horizontal_carousel_container.thin a.slider_control
{
	top: 50px;
}
.horizontal_carousel_container .horizontal_carousel>li
{
	float: left;
	width: 210px;
	clear: none;
	margin-right: 30px;
	margin-top: 0;
}
.horizontal_carousel_container.big .horizontal_carousel>li
{
	width: auto;
}
.column_1_1 .horizontal_carousel_container .horizontal_carousel.visible-3>li
{
	width: 330px;
}
.column_1_1 .horizontal_carousel_container .horizontal_carousel.visible-4>li
{
	width: 240px;
}
.column_2_3 .horizontal_carousel_container.thin .horizontal_carousel.visible-5>li,
.gallery_popup .horizontal_carousel_container.thin .horizontal_carousel.visible-8 > li
{
	width: 130px;
	margin-right: 10px;
}
.gallery_popup .horizontal_carousel_container.thin
{
	width: 970px;
	margin-left: auto;
	margin-right: auto;
}
.blog.horizontal_carousel>li
{
	padding-bottom: 1px;
}
.column_2_3 .horizontal_carousel_container.thin.gallery_control  .horizontal_carousel.visible-5>li,
.gallery_popup .horizontal_carousel_container.thin.gallery_control .horizontal_carousel.visible-8 > li
{
	opacity: 0.5;
}
.column_2_3 .horizontal_carousel_container.thin.gallery_control .horizontal_carousel.visible-5>li.current,
.column_2_3 .horizontal_carousel_container.thin.gallery_control .horizontal_carousel.visible-5>li:hover,
.gallery_popup .horizontal_carousel_container.thin.gallery_control .horizontal_carousel.visible-8>li.current,
.gallery_popup .horizontal_carousel_container.thin.gallery_control .horizontal_carousel.visible-8>li:hover
{
	opacity: 1;
}
.column_2_3 .horizontal_carousel_container.thin.gallery_control  .horizontal_carousel.visible-5>li img,
.gallery_popup .horizontal_carousel_container.thin.gallery_control .horizontal_carousel.visible-8 > li img
{
	transition: none;
	-webkit-transition: none;
	opacity: 1;
}
.horizontal_carousel .author h6
{
	margin-top: 10px;
}
/* --- preloader --- */
.pr_preloader
{
	display: block;
	height: auto;
	min-height: 100px;
	background: url('../images/preloader.gif') no-repeat center center;
}
.blog .post>a>img,
.grid_view .post>a>img,
.post.single .post_image img,
.slider li.slide img,
.pr_preload
{
	display: none;
}
.blog .post>a>.icon,
.grid_view .post>a>.icon,
.post.single .post_image .icon,
.slider li.slide .icon
{
	display: none;
}
.blog.small .post>a>img,
.blog.small .post>a>.icon
{
	display: block;
}
/* --- icons --- */
.icon/*,
.icon .info*/
{
	display: block;
	position: absolute;
	width: 45px;
    height: 45px;
    z-index: 1;
	background-color: rgba(66,73,79,0.8);
	background-repeat: no-repeat;
	text-align: center;
}
.icon.small
{
	width: 29px;
	height: 29px;
}
/*.icon .info
{
	width: 0%;
	height: auto;
	z-index: 1;
	left: 45px;
	font-family: 'Roboto Condensed';
	color: #FFF;
	font-size: 18px;
	transition: width 250ms;
	overflow: hidden;
	text-align: center;
	padding-top: 14px;
	padding-bottom: 13px;
}
.icon:hover .info
{
	width: 100%;
}*/
.icon span
{
	display: inline-block;
    color: #fff;
    font-family: 'Roboto Condensed';
    font-size: 18px;
    margin-top: 13px;
}
.icon.video
{
	background-image: url("../images/icons/media/video.png");
}
.icon.gallery
{
	background-image: url("../images/icons/media/gallery.png");
}
.icon.small.video
{
	background-image: url("../images/icons/media/small_video.png");
}
.icon.small.gallery
{
	background-image: url("../images/icons/media/small_gallery.png");
}
.icon.small.review
{
	background-image: url("../images/icons/media/small_review.png");
}
.icon.fullscreen
{
	cursor: pointer;
	background-image: url("../images/icons/media/fullscreen.png");
}
.icon.fullscreen:hover
{
	background-color: #ED1C24;
}
.icon.fullscreen.animated
{
	visibility: hidden;
	-webkit-animation-duration: 300ms;
    animation-duration: 300ms;
}
/* --- authors --- */
.authors .author
{
	float: left;
	width: 150px;
	margin-top: 30px;
	margin-left: 30px;
	text-align: center;
}
.authors .author:nth-child(2n+1)
{
	margin-left: 0;
}
.author h6
{
	color: #7C7C7C;
	font-weight: bold;
}
.author .thumb, .author .number, .author .thumb img
{
	display: block;
}
.author .thumb
{
	position: relative;
}
.author .details
{
	clear: both;
	margin-top: 10px;
}
.author .details h5
{
	margin-top: 0;
}
.author .number,
.author .number.odometer
{
	position: absolute;
	width: 50px;
	bottom: 7px;
	right: 0;
	z-index: 1;
	padding-right: 0;
	text-align: center;
}
.author .value_bar_container
{
	position: absolute;
	width: 50px;
	height: 100%;
    right: 0;
    bottom: 0;
}
.author .value_bar
{
	position: absolute;
	width: 50px;
	height: 100%;
	bottom: 0;
	background: #F0F0F0;
}
.author a img
{
	width: auto;
	opacity: 1;
	transition: opacity 0.4s ease-in-out 0s;
	-webkit-transition: opacity 0.4s ease-in-out 0s;
}
.author a:hover img
{
	opacity: 0.8;
	transition: opacity 0.4s ease-in-out 0s;
	-webkit-transition: opacity 0.4s ease-in-out 0s;
}
.horizontal_carousel .author a img
{
	width: 100%;
}
.author .avatar_block
{
	float: left;
	margin-right: 30px;
}
.author .avatar_block img
{
	display: block;
	width: 240px;
}
.authors_list .author
{
	margin-top: 50px;
}
.authors_list .author:first-child
{
	margin-top: 0;
}
.authors_list .author blockquote .author
{
	margin-top: 23px;
	margin-left: 0;
}
.authors_list .content
{
	position: relative;
	float: left;
	width: 420px;
}
.authors_list .social_icons
{
	position: absolute;
	right: 0;
}
.authors_list .more
{
	width: 74px;
}
.authors_list .details
{
	position: relative;
	margin-top: 0;
	padding: 15px 20px;
}
.authors_list .author .value_bar_container
{
	left: 0;
	right: auto;
}
.authors_list .author .value_bar
{
	width: 100%;
}
.authors_list .details .columns 
{
	position: relative;
	z-index: 1;
}
.authors_list .details .columns .column
{
	width: 85px;
}
.authors_list .details .columns  .number
{
	position: static;
	width: auto;
	text-align: left;
	font-size: 18px;
	font-weight: 400;
}
/* --- item content --- */
.item_content
{
	margin-top: 10px;
	list-style: none;
}
.page_margin_top.item_content:first-child
{
	margin-top: 30px;
}
.item_content:first-child
{
	margin-top: 0;
}
.item_content .features_icon
{
	float: left;
	display: block;
	width: 80px;
	height: 80px;
	margin-right: 20px;
	margin-bottom: 5px;
	background-color: #F0F0F0;
	background-repeat: no-repeat;
	border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
}
.item_content.border_top
{
	padding-top: 30px;
	border-top: 1px solid #E9E9E9;
}
.item_content .not_found
{
	width: 110px;
	height: 110px;
	background-image: url("../images/icons/other/404.png");
}
.app
{
	background-image: url("../images/icons/features/app.png");
}
.calendar
{
	background-image: url("../images/icons/features/calendar.png");
}
.chart
{
	background-image: url("../images/icons/features/chart.png");
}
.chat
{
	background-image: url("../images/icons/features/chat.png");
}
.clock
{
	background-image: url("../images/icons/features/clock.png");
}
.database
{
	background-image: url("../images/icons/features/database.png");
}
.document
{
	background-image: url("../images/icons/features/document.png");
}
.envelope
{
	background-image: url("../images/icons/features/envelope.png");
}
.faq
{
	background-image: url("../images/icons/features/faq.png");
}
.graph
{
	background-image: url("../images/icons/features/graph.png");
}
.image
{
	background-image: url("../images/icons/features/image.png");
}
.laptop
{
	background-image: url("../images/icons/features/laptop.png");
}
.magnifier
{
	background-image: url("../images/icons/features/magnifier.png");
}
.features_icon.mobile
{
	background-image: url("../images/icons/features/mobile.png");
}
.pin
{
	background-image: url("../images/icons/features/pin.png");
}
.printer
{
	background-image: url("../images/icons/features/printer.png");
}
.quote
{
	background-image: url("../images/icons/features/quote.png");
}
.screen
{
	background-image: url("../images/icons/features/screen.png");
}
.speaker
{
	background-image: url("../images/icons/features/speaker.png");
}
.video
{
	background-image: url("../images/icons/features/video.png");
}
/* --- latest news scrolling list ---*/
.latest_news_scrolling_list_container li
{
	float: left;
	padding: 8px 14px 17px 15px;
}
.latest_news_scrolling_list_container li.posts
{
	padding: 0;
	overflow: hidden;
}
.latest_news_scrolling_list_container .category
{
	border-right: 1px solid #464D53;
	border-left: 1px solid #464D53;
	font-size: 12px;
	font-weight: bold;
	line-height: 120%;
}
.latest_news_scrolling_list_container .date
{
	padding-left: 0;
}
.latest_news_scrolling_list_container .date .timeago
{
	display: none;
}
.latest_news_scrolling_list_container .date .timeago.current
{
	display: inline;
}
.latest_news_scrolling_list_container li.left,
.latest_news_scrolling_list_container li.right
{
	border-right: 1px solid #464D53;
	cursor: pointer;
}
.latest_news_scrolling_list_container li.left
{
	padding: 0px 8px 9px 7px;
}
.latest_news_scrolling_list_container li.right
{
	padding: 0px 7px 9px 8px;
}
.latest_news_scrolling_list_container li.left:hover,
.latest_news_scrolling_list_container li.right:hover
{
	background: #464D53;
}
.latest_news_scrolling_list_container .left a,
.latest_news_scrolling_list_container .right a
{
	display: block;
	width: 29px;
	height: 29px;
	background: no-repeat 0 0;
}
.latest_news_scrolling_list_container .left a
{
	background-image: url("../images/icons/navigation/dark_bg/navigation_latest_arrow_left.png");
}
.latest_news_scrolling_list_container .right a
{
	background-image: url("../images/icons/navigation/dark_bg/navigation_latest_arrow_right.png");
}
.latest_news_scrolling_list_container .left:hover a,
.latest_news_scrolling_list_container .right:hover a
{
	background-position: 0 -29px;
}
.latest_news_scrolling_list_container li .timeago
{
	color: #858D94;
	font-size: 14px;
	text-decoration: none;
}
.latest_news_scrolling_list_container li .latest_news_scrolling_list
{
	height: 46px;
	overflow: hidden;
}
.latest_news_scrolling_list_container li .latest_news_scrolling_list li
{
	padding-right: 5px;
}
.latest_news_scrolling_list_container li .latest_news_scrolling_list a
{
	color: #FFF;
	font-size: 14px;
}
/* --- social icons --- */
.social_icons li
{
	float: left;
	padding: 0 5px 0 0px;
}
.social_icons .social_icon
{
	display: block;
	width: 30px;
	height: 30px;
	background-repeat: none;
	background-position: 0 0;
	outline: none;
}
.header_top_bar .social_icons
{
	float: right;
}
.header_top_bar .social_icons li
{
	padding: 8px 0 8px 5px;
}
.social_icons .social_icon:hover,
.social_icons.colors .social_icon
{
	background-position: 0 -30px;
}
.social_icons.colors .social_icon
{
	opacity: 1;
	transition: opacity 0.2s ease-in-out 0s;
}
.social_icons.colors .social_icon:hover
{
	opacity: 0.7;
	transition: opacity 0.2s ease-in-out 0s;
}
.behance
{
	background-image: url("../images/icons/social/behance.png");
}
.bing
{
	background-image: url("../images/icons/social/bing.png");
}
.blogger
{
	background-image: url("../images/icons/social/blogger.png");
}
.deezer
{
	background-image: url("../images/icons/social/deezer.png");
}
.designfloat
{
	background-image: url("../images/icons/social/designfloat.png");
}
.deviantart
{
	background-image: url("../images/icons/social/deviantart.png");
}
.digg
{
	background-image: url("../images/icons/social/digg.png");
}
.digg
{
	background-image: url("../images/icons/social/digg.png");
}
.dribbble
{
	background-image: url("../images/icons/social/dribbble.png");
}
.envato
{
	background-image: url("../images/icons/social/envato.png");
}
.facebook
{
	background-image: url("../images/icons/social/facebook.png");
}
.flickr
{
	background-image: url("../images/icons/social/flickr.png");
}
.form
{
	background-image: url("../images/icons/social/form.png");
}
.forrst
{
	background-image: url("../images/icons/social/forrst.png");
}
.foursquare
{
	background-image: url("../images/icons/social/foursquare.png");
}
.friendfeed
{
	background-image: url("../images/icons/social/friendfeed.png");
}
.googleplus
{
	background-image: url("../images/icons/social/googleplus.png");
}
.instagram
{
	background-image: url("../images/icons/social/instagram.png");
}
.linkedin
{
	background-image: url("../images/icons/social/linkedin.png");
}
.mail
{
	background-image: url("../images/icons/social/mail.png");
}
.mobile
{
	background-image: url("../images/icons/social/mobile.png");
}
.myspace
{
	background-image: url("../images/icons/social/myspace.png");
}
.picasa
{
	background-image: url("../images/icons/social/picasa.png");
}
.pinterest
{
	background-image: url("../images/icons/social/pinterest.png");
}
.reddit
{
	background-image: url("../images/icons/social/reddit.png");
}
.rss
{
	background-image: url("../images/icons/social/rss.png");
}
.skype
{
	background-image: url("../images/icons/social/skype.png");
}
.soundcloud
{
	background-image: url("../images/icons/social/soundcloud.png");
}
.spotify
{
	background-image: url("../images/icons/social/spotify.png");
}
.stumbleupon
{
	background-image: url("../images/icons/social/stumbleupon.png");
}
.technorati
{
	background-image: url("../images/icons/social/technorati.png");
}
.tumblr
{
	background-image: url("../images/icons/social/tumblr.png");
}
.twitter
{
	background-image: url("../images/icons/social/twitter.png");
}
.vimeo
{
	background-image: url("../images/icons/social/vimeo.png");
}
.wykop
{
	background-image: url("../images/icons/social/wykop.png");
}
.xing
{
	background-image: url("../images/icons/social/xing.png");
}
.youtube
{
	background-image: url("../images/icons/social/youtube.png");
}
.dark .behance,
.colors .behance
{
	background-image: url("../images/icons/social/dark_bg/behance.png");
}
.dark .bing,
.colors .bing
{
	background-image: url("../images/icons/social/dark_bg/bing.png");
}
.dark .blogger,
.colors .blogger
{
	background-image: url("../images/icons/social/dark_bg/blogger.png");
}
.dark .deezer,
.colors .deezer
{
	background-image: url("../images/icons/social/dark_bg/deezer.png");
}
.dark .designfloat,
.colors .designfloat
{
	background-image: url("../images/icons/social/dark_bg/designfloat.png");
}
.dark .deviantart,
.colors .deviantart
{
	background-image: url("../images/icons/social/dark_bg/deviantart.png");
}
.dark .digg,
.colors .digg
{
	background-image: url("../images/icons/social/dark_bg/digg.png");
}
.dark .digg,
.colors .digg
{
	background-image: url("../images/icons/social/dark_bg/digg.png");
}
.dark .dribbble,
.colors .dribbble
{
	background-image: url("../images/icons/social/dark_bg/dribbble.png");
}
.dark .envato,
.colors .envato
{
	background-image: url("../images/icons/social/dark_bg/envato.png");
}
.dark .facebook,
.colors .facebook
{
	background-image: url("../images/icons/social/dark_bg/facebook.png");
}
.dark .flickr,
.colors .flickr
{
	background-image: url("../images/icons/social/dark_bg/flickr.png");
}
.dark .form,
.colors .form
{
	background-image: url("../images/icons/social/dark_bg/form.png");
}
.dark .forrst,
.colors .forrst
{
	background-image: url("../images/icons/social/dark_bg/forrst.png");
}
.dark .foursquare,
.colors .foursquare
{
	background-image: url("../images/icons/social/dark_bg/foursquare.png");
}
.dark .friendfeed,
.colors .friendfeed
{
	background-image: url("../images/icons/social/dark_bg/friendfeed.png");
}
.dark .googleplus,
.colors .googleplus
{
	background-image: url("../images/icons/social/dark_bg/googleplus.png");
}
.dark .instagram,
.colors .instagram
{
	background-image: url("../images/icons/social/dark_bg/instagram.png");
}
.dark .linkedin,
.colors .linkedin
{
	background-image: url("../images/icons/social/dark_bg/linkedin.png");
}
.dark .mail,
.colors .mail
{
	background-image: url("../images/icons/social/dark_bg/mail.png");
}
.dark .mobile,
.colors .mobile
{
	background-image: url("../images/icons/social/dark_bg/mobile.png");
}
.dark .myspace,
.colors .myspace
{
	background-image: url("../images/icons/social/dark_bg/myspace.png");
}
.dark .picasa,
.colors .picasa
{
	background-image: url("../images/icons/social/dark_bg/picasa.png");
}
.dark .pinterest,
.colors .pinterest
{
	background-image: url("../images/icons/social/dark_bg/pinterest.png");
}
.dark .reddit,
.colors .reddit
{
	background-image: url("../images/icons/social/dark_bg/reddit.png");
}
.dark .rss,
.colors .rss
{
	background-image: url("../images/icons/social/dark_bg/rss.png");
}
.dark .skype,
.colors .skype
{
	background-image: url("../images/icons/social/dark_bg/skype.png");
}
.dark .soundcloud,
.colors .soundcloud
{
	background-image: url("../images/icons/social/dark_bg/soundcloud.png");
}
.dark .spotify,
.colors .spotify
{
	background-image: url("../images/icons/social/dark_bg/spotify.png");
}
.dark .stumbleupon,
.colors .stumbleupon
{
	background-image: url("../images/icons/social/dark_bg/stumbleupon.png");
}
.dark .technorati,
.colors .technorati
{
	background-image: url("../images/icons/social/dark_bg/technorati.png");
}
.dark .tumblr,
.colors .tumblr
{
	background-image: url("../images/icons/social/dark_bg/tumblr.png");
}
.dark .twitter,
.colors .twitter
{
	background-image: url("../images/icons/social/dark_bg/twitter.png");
}
.dark .vimeo,
.colors .vimeo
{
	background-image: url("../images/icons/social/dark_bg/vimeo.png");
}
.dark .wykop,
.colors .wykop
{
	background-image: url("../images/icons/social/dark_bg/wykop.png");
}
.dark .xing,
.colors .xing
{
	background-image: url("../images/icons/social/dark_bg/xing.png");
}
.dark .youtube,
.colors .youtube
{
	background-image: url("../images/icons/social/dark_bg/youtube.png");
}
.colors .behance
{
	background-color: #000;
}
.colors .bing
{
	background-color: #FDB700;
}
.colors .blogger
{
	background-color: #F7974A;
}
.colors .deezer
{
	background-color: #36BDF4;
}
.colors .designfloat
{
	background-color: #F07D00;
}
.colors .deviantart
{
	background-color: #58735C;
}
.colors .digg
{
	background-color: #546B9F;
}
.colors .dribbble
{
	background-color: #E94D8A;
}
.colors .envato
{
	background-color: #83B542;
}
.colors .facebook
{
	background-color: #3B5A9B;
}
.colors .flickr
{
	background-color: #3565AB;
}
.colors .form
{
	background-color: #ED1C24;
}
.colors .forrst
{
	background-color: #859563;
}
.colors .foursquare
{
	background-color: #0086BE;
}
.colors .friendfeed
{
	background-color: #3C75BA;
}
.colors .googleplus
{
	background-color: #DD4B39;
}
.colors .instagram
{
	background-color: #5280A5;
}
.colors .linkedin
{
	background-color: #007BB6;
}
.colors .mail
{
	background-color: #C72C58;
}
.colors .mobile
{
	background-color: #ED1C24;
}
.colors .myspace
{
	background-color: #000;
}
.colors .picasa
{
	background-color: #65B145;
}
.colors .pinterest
{
	background-color: #CB2027;
}
.colors .reddit
{
	background-color: #FE5D05;
}
.colors .rss
{
	background-color: #F38F36;
}
.colors .skype
{
	background-color: #40BEEE;
}
.colors .soundcloud
{
	background-color: #F76E10;
}
.colors .spotify
{
	background-color: #85BF40;
}
.colors .stumbleupon
{
	background-color: #EB4C23;
}
.colors .technorati
{
	background-color: #56B849;
}
.colors .tumblr
{
	background-color: #4C6E8F;
}
.colors .twitter
{
	background-color: #00ACED;
}
.colors .vimeo
{
	background-color: #5CB5E6;
}
.colors .wykop
{
	background-color: #F15A25;
}
.colors .xing
{
	background-color: #1E6A70;
}
.colors .youtube
{
	background-color: #BC110A;
}
/* --- search --- */
.search
{
	float: right;
	border-left: 1px solid #464D53;
	border-right: 1px solid #464D53;
	margin-left: 15px;
	/*background: #42494F;*/
	line-height: 1;
}
.search .search_input
{
	float: left;
	width: 70px;
	font-size: 14px;
	background: #363B40;
	color: #FFF;
	border: none;
	margin: 0;
	padding: 15px 14px 15px 15px;
	border-right: 1px solid #464D53;
	transition: width 0.5s;
	-moz-transition: width 0.5s; /* Firefox */
	-webkit-transition: width 0.5s; /* Safari and Chrome */
	-o-transition: width 0.5s; /* Opera */
}
.search .search_input:focus
{
	width: 130px;
}
.search .search_input.hint
{
	color: #FFF;
}
.search .search_input::-webkit-input-placeholder 
{
	color: #858d94;
}
.search .search_input:-moz-placeholder 
{
	color: #858d94;
    opacity:  1;
}
.search .search_input::-moz-placeholder
{
	color: #858d94;
    opacity:  1;
}
.search .search_input:-ms-input-placeholder 
{
	color: #858d94;
}
.search .search_submit
{
	display: block;
	float: right;
	width: 30px;
	height: 46px;
	padding: 0 22px;
	background: url("../images/icons/other/dark_bg/header_search.png") 0 0 no-repeat;
	border: none;
	cursor: pointer;
}
.search .search_submit:hover
{
	background-color: #464D53;
	background-position: 0 -46px;
}
/* --- buttons --- */
.more,
.more[type="submit"]
{	
	display: block;
	border: 1px solid #E9E9E9;
	color: #25282A; 
	font-family: 'Arial';
	font-size: 12px;
	font-weight: bold;
	padding: 14px 13px 13px;
	text-align: center;
	cursor: pointer;
}
.more.highlight,
.more.active:hover
{
	border: 2px solid #ED1C24;
	color: #ED1C24;
	padding: 13px 12px 12px;
	background: transparent;
}
.more.active,
.more:hover
{
	color: #FFF;
	background: #ED1C24;
	border-color: #ED1C24;
}
.more.medium,
.more.medium[type="submit"]
{
	font-size: 13px;
	padding: 15px 13px 14px;
}
.more.medium.highlight,
.more.medium.active:hover
{
	padding: 14px 12px 13px;
}
.more.big,
.more.big[type="submit"]
{
	font-size: 14px;
	padding: 20px 29px 19px;
}
.more.big.highlight,
.more.big.active:hover
{
	padding: 19px 28px 18px;
}
/* --- copyright row --- */
.copyright_row, 
.copyright_row h6
{
	font-family: 'Arial';
	font-size: 12px;
	color: #9DA4AB;
}
.copyright_row .footer_menu
{
	float: right;
}
.copyright_row .footer_menu li
{
	margin-left: 25px;
	margin-right: 0;
}
/* --- gallery popup --- */
.gallery_popup
{
	position: absolute;
	display: none;
	width: 100%;
	z-index: 101;
	left: 0;
	overflow-y: scroll;
	background: #2D3136;
}
.gallery_overlay
{
	left: 0px;
	top: 0px;
	position: absolute;
	background-color: #2D3136;
	z-index: 100;
}
.gallery_popup .header_container
{
	background: transparent;
	border-bottom: 1px solid #464D53;
	padding: 30px 0;
}
.gallery_popup .header
{
	text-align: left;
	padding: 0;
}
.gallery_popup .header h1,
.gallery_popup .header h1 a
{
	float: left;
	font-family: 'Roboto Condensed';
	font-size: 50px;
	color: #FFF;
}
.gallery_popup h3
{
	font-size: 18px;
	color: #FFF;
}
.gallery_popup p
{
	color: #D7DCE0;
}
.gallery_popup p
{
	font-size: 16px;
}
.gallery_popup .read_more
{
	color: #FFF;
}
.gallery_close
{
	float: right;
	display: block;
	width: 23px;
	height: 23px;
	background: url("../images/icons/other/dark_bg/gallery_close.png") no-repeat 0 0;
	margin-top: 15px;
}
.gallery_close:hover
{
	background-position: 0 -23px;
}
.gallery_popup .slider_navigation
{	
	position: static;
	float: left;
}
.gallery_popup .slider_navigation .slider_control:first-child
{
	margin-right: 2px;
}
.gallery_popup .slider_navigation .slider_control a
{
	background-color: #42494F;
}
.gallery_popup .slider_navigation .slider_control a:hover
{
	background-color: #ED1C24;
}
.gallery_popup .slider_info
{
	float: right;
	background: #42494F;
	font-size: 18px;
	font-family: 'Roboto Condensed';
	padding: 13px 15px 14px;
}
.gallery_popup .share_box
{
	border-top-color: #464D53;
	border-bottom: none;
}
.gallery_popup .share_box label
{
	color: #FFF;
}
/* --- prettyPhoto --- */
/*div.pp_default .pp_nav
{
	margin-top: 9px;
}
div.pp_default .pp_close
{
	margin-top: 7px;
}*/
div.pp_default .pp_content_container .pp_left,
div.pp_default .pp_content_container .pp_right,
div.pp_default .pp_top .pp_left,
div.pp_default .pp_top .pp_middle,
div.pp_default .pp_top .pp_right,
div.pp_default .pp_bottom .pp_left,
div.pp_default .pp_bottom .pp_middle,
div.pp_default .pp_bottom .pp_right
{
	background: none;
	padding: 0;
}
div.pp_default .pp_content, div.light_rounded .pp_content
{
	padding: 10px;
}
div.pp_default .pp_description
{
	font-size: 12px;
	color: #7C7C7C;
}
div.pp_default .pp_close
{
	margin-top: 8px;
	background: url("../images/icons/other/close.png") 0 0 no-repeat;
}
div.pp_default .pp_close:hover
{
	opacity: 1;
	background-position: 0 -30px;
}
/* --- map --- */
.contact_map
{
	width: 100%;
	height: 350px;
}
.column_1_3 .contact_map
{
	height: 330px;
}
/* --- divider --- */
.divider
{
	background: #F0F0F0;
    border: none;
    height: 10px;
	margin: 0;
}
.divider.page_margin_top
{
	margin-top: 30px;
}
.divider_block
{
	margin-top: 25px;
}
.divider_block .divider
{
	float: left;
}
.divider.first
{
	width: 30px;
}
.divider.subheader_arrow
{
	width: 20px;
	height: 30px;
	background: url("../images/icons/other/subheader_arrow.png") no-repeat 0 0;
}
.divider.last
{
	width: 1000px;
}
/* --- misc --- */
.scroll_top
{
	float: right;
	background: url("../images/icons/navigation/go_top_arrow.png") no-repeat center right;
	padding-right: 25px;
	font-size: 18px;
	font-family: 'Roboto';
	color: #FFF;
	line-height: 140%;
}
.button_preview
{
	float: left;
	margin-top: 30px;
	width: 90px;
	margin-right: 30px;
}
.button_preview.medium
{
	width: 109px;
}
.button_preview.big
{
	width: 108px;
}
.header h1 a,
.sf-menu a:hover,
.social_icons .social_icon:hover,
.more:hover,
.read_more:hover,
.tabs_navigation li a:hover,
.tabs_navigation li a:focus,
.tabs_navigation li a:active,
.post .comments_number:hover,
.taxonomies a:hover,
.pagination li a:hover,
.slider_content_box li.category a:hover,
.gallery_close:hover
{
	text-decoration: none;
}
input
{
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0;
	-webkit-appearance: none;
}
input[type='checkbox']
{
	-webkit-appearance: checkbox;
}
input[type='radio']
{
	-webkit-appearance: radio;
}
:focus 
{
	outline:none;
}
::-moz-focus-inner 
{
	border:0;
}
body.lock-position 
{
	position: fixed;
	overflow: hidden;
	width: 100%;
	height: 100%;
}
